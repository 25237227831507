import { useTranslation } from 'react-i18next';
import { LoginCredentialsBox, color_brand_primary_base } from 'tridonic-web-ui'
import { useEffect, useState } from "react";

export default function LoginPage({ setIsAuth }) {
    const { t } = useTranslation();
    const [notificationText, setNotificationText] = useState('')
    const [notificationType, setNotificationType] = useState('')
    function login({ username, password }) { 
    
        if (username === process.env.REACT_APP_AUTH_USERNAME 
            && password === process.env.REACT_APP_AUTH_PASSWORD) {
            localStorage.setItem('isAuth', true);
            setIsAuth(true)
        }
        else{
            setNotificationText("The user name or password is incorrect.")
            setNotificationType( 'error')
            
        }
    }

    useEffect(() => {
        setNotificationText('')
        setNotificationType( '')

    }, [])

    return (

            <LoginCredentialsBox
               notificationType ={notificationType}
               notificationText={notificationText}
                backgroundStyle={{ backgroundColor: color_brand_primary_base, width: '100vw', height: '100vh' }}
                softwareName="CompanionSUITE"
                leadingAction={(username, password) => { login({username: username, password:password}); }}
                trailingAction={(params) => { 
                    setNotificationText(t('Not allowed to reset password!'))
                    setNotificationType( 'error')         
                    console.log('clicked reset password with params: ', params) }}
            />
            
        )
}