import { useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Input, Dropdown, useThemeContext, useNotificationsContext, HorizontalFlow } from "tridonic-web-ui";

import services from "../../../services/generic/services";
import accessTokenProvider from '../../../services/generic/session'
import CSMasterpage from "../../../components/CSMasterpage";
import PageOverlay from "../../../components/PageOverlay";
import NewAccountPageStyle from "./NewAccountPageStyle";
//import HorizontalFlow from "tridonic-web-ui/dist/Layout/HorizontalFlow";

export default function NewAccountPage({
    location
}) {

    const { theme } = useThemeContext();
    const { EmptyDiv } = useMemo(() => NewAccountPageStyle(theme), [theme]);

    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState();
    const [countriesData, setCountriesData] = useState();
    const history = useHistory()
    const { addNotification } = useNotificationsContext()
    const [criticalError, setCriticalError] = useState('');
    const { planId } = useParams()
    const { t } = useTranslation();
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    // Form fields
    const [selectedCountry, setSelectedCountry] = useState();
    const [formCompanyName, setFormCompanyName] = useState();
    const [formCompanyVat, setFormCompanyVat] = useState();
    const [formCompanyPhone, setFormCompanyPhone] = useState();
    const [formCompanyCity, setFormCompanyCity] = useState();
    const [formCompanyAddress, setFormCompanyAddress] = useState();
    const [formCompanyPostalCode, setFormCompanyPostalCode] = useState();

    // Validation fields
    const [formCompanyNameIsValid, setFormCompanyNameIsValid] = useState();
    const [formCompanyVatIsValid, setFormCompanyVatIsValid] = useState();
    const [formCompanyPhoneIsValid, setFormCompanyPhoneIsValid] = useState();
    const [formCompanyCityIsValid, setFormCompanyCityIsValid] = useState();
    const [formCompanyAddressIsValid, setFormCompanyAddressIsValid] = useState();
    const [formCompanyPostalCodeIsValid, setFormCompanyPostalCodeIsValid] = useState();
    const [forceValidation, setForceValidation] = useState(false);

    const name = location?.state?.name
    const address = location !== undefined ? location?.state?.address : null;
    const countryCode = location !== undefined ? location?.state?.countryCode : null;
    const vat = location !== undefined ? location?.state?.vat : null;
    const phone = location !== undefined ? location?.state?.phone : null;
    const city = location !== undefined ? location?.state?.city : null;
    const postalcode = location !== undefined ? location?.state?.postalcode : null;


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        // Data was already fetched
        if (name) setFormCompanyName(name);
        if (vat) setFormCompanyVat(vat)
        if (phone) setFormCompanyPhone(phone);
        if (countryCode) setSelectedCountry(countryCode);
        if (city) setFormCompanyCity(city);
        if (address) setFormCompanyAddress(address);
        if (postalcode) setFormCompanyPostalCode(postalcode);

        setForceValidation(true)


    }, [name, address, countryCode, vat, phone, city, postalcode]);




    function handleUserResponse(userResponse) {
        if (userResponse.success) {
            setUserData(userResponse.data);
        } else {
            if (userResponse.data.isActionError) {
                addNotification({ text: t(userResponse.data.message), type: 'critical' })
            } else {
                setCriticalError(userResponse.data);
            }
        }
    }


    function handleCountriesResponse(countriesResponse) {
        let countriesDataResponse = countriesResponse?.data;
        if (countriesResponse?.success) {
            setCountriesData(countriesDataResponse);
        } else {
            setCriticalError(countriesDataResponse);
        }
    }

    function fetchData() {
        setIsLoading(true);
        setIsFormDirty(false);
        setIsDataLoaded(false);
        Promise.all([
            services.getUser({ tokenProvider: accessTokenProvider }),
            services.getCountries({ tokenProvider: accessTokenProvider })
        ]).then(results => {
            let [userResponse, countriesResponse] = results;
            handleUserResponse(userResponse);
            handleCountriesResponse(countriesResponse);
            //setIsAccountHolder(permition.isCompanyAccountHolder({ company: companyResponse?.data, userId: userResponse?.data?.id }));
        }).catch((err) => {
            console.error(err);
            //TODO do something here
        }).finally(() => {
            setIsLoading(false);
            setIsDataLoaded(true);
        });
    }

    useEffect(() => {
        // Data was already fetched

        // The only required field is the company name
        // setIsFormValid(formCompanyName && formCompanyName.length > 0)
        if (formCompanyName && formCompanyName.length > 0 &&
            formCompanyNameIsValid &&
            formCompanyVatIsValid &&
            formCompanyPhoneIsValid &&
            formCompanyCityIsValid &&
            formCompanyAddressIsValid &&
            formCompanyPostalCodeIsValid
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
        setIsFormDirty(true);


    }, [forceValidation, formCompanyNameIsValid, formCompanyVatIsValid, formCompanyPhoneIsValid, formCompanyAddressIsValid, formCompanyAddressIsValid, formCompanyPostalCodeIsValid]);


    async function Continue() {
        if (!isFormValid) {
            addNotification({
                text: t('NewAccount.Company.Form.NameValidation'),
                type: 'warning',
                duration: 8
            })
            return
        }
        let createAccountMetadata = {
            name: formCompanyName,
            planId: planId,
            address: formCompanyAddress,
            countryCode: selectedCountry,
            vat: formCompanyVat,
            phone: formCompanyPhone,
            city: formCompanyCity,
            postalcode: formCompanyPostalCode
        };
        history.push({ pathname: `/new-account/legal`, state: createAccountMetadata })
    }

    async function cancel() {
        history.push({ pathname: `/` })
    }




    return (
        <>
            <CSMasterpage
                title={t('NewAccount.Company.Title')}
                loading={isLoading}
                titleNote={t('NewAccount.Company.Step2')}
                footerActions={[
                    {
                        action: () => Continue(),
                        text: t('NewAccount.Company.Next'),
                        type: 'primary',
                        active: isFormValid && isFormDirty
                    },
                    {
                        action: () => cancel(),
                        text: t('NewAccount.Company.Cancel'),
                        type: 'ghost',
                        active: true
                    }
                ]}
                criticalError={criticalError}
            >

                <HorizontalFlow
                    useBottomMargin={false}
                    maxItemsPerLine="4"
                    useTopMargin={false}
                    width="100%">
                    <div>
                        <div>
                            <Input
                                active={false}
                                label={t('Account.Company.CompanySection.AccountHolder')}
                                loading={isLoading}
                                required={true}
                                showValidationError={true}
                                type="text"
                                value={userData?.name ? userData.name : ''}
                            />
                        </div>
                    </div>
                    <Input
                        active={false}
                        label={t('Account.Company.CompanySection.AccountHolderEmail')}
                        loading={isLoading}
                        required={true}
                        requiredMessage={t('NewAccount.Company.Form.NameRequired')}
                        showValidationError={true}
                        type="text"
                        value={userData?.email}
                    />
                    <EmptyDiv />
                    <EmptyDiv />
                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.Name')}
                        loading={isLoading}
                        setValue={setFormCompanyName}
                        placeholder={t('NewAccount.Company.Form.NamePlaceholder')}
                        required={true}
                        forceValidations={false}
                        showValidationError={true}
                        validationRegExpString="^[a-zA-Z\s]{1,30}$"
                        validationErrorMessage={t('NewAccount.Company.Form.NameValidationErrorMessage')}
                        requiredMessage={t('NewAccount.Company.Form.NameRequired')}
                        type="text"
                        value={formCompanyName}
                        min={1}
                        max={30}
                        validationCallback={(valid, text) => { setFormCompanyNameIsValid(valid); console.log('validation formCompanyNameIsValid:', valid, text) }}

                    />

                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.Vat')}  //"Fiscal number/VAT *"
                        loading={isLoading}
                        setValue={setFormCompanyVat}
                        placeholder={t('NewAccount.Company.Form.VatPlaceholder')}
                        required={false}
                        forceValidations={true}
                        showValidationError={formCompanyVat !== null}
                        validationRegExpString="^[\d ]{0,20}$"
                        validationErrorMessage={t('NewAccount.Company.Form.VatValidationErrorMessage')}
                        type="text"
                        value={formCompanyVat}
                        width="100%"
                        max={20}
                        validationCallback={(valid, text) => { setFormCompanyVatIsValid(valid); console.log('validation formCompanyVatIsValid:', valid, text) }}

                    />

                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.Phone')}
                        loading={isLoading}
                        setValue={setFormCompanyPhone}
                        placeholder={t('NewAccount.Company.Form.PhonePlaceholder')}
                        required={false}
                        showValidationError={formCompanyPhone !== null}
                        forceValidations={formCompanyPhone !== undefined}
                        validationRegExpString="^$|\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                        validationErrorMessage={t('NewAccount.Company.Form.PhoneValidationErrorMessage')}
                        type="int"
                        value={formCompanyPhone}
                        width="100%"
                        margin="10px"
                        max={20}
                        validationCallback={(valid, text) => { setFormCompanyPhoneIsValid(valid); console.log('validation formCompanyPhoneIsValid:', valid, text) }}

                    />
                    <EmptyDiv />


                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.Address')}
                        loading={isLoading}
                        setValue={setFormCompanyAddress}
                        placeholder={t('NewAccount.Company.Form.AddressPlaceholder')}
                        required={false}
                        showValidationError={formCompanyAddress !== null}
                        forceValidations={true}
                        validationRegExpString="^.{0,50}$"
                        validationErrorMessage={t('NewAccount.Company.Form.AddressValidationErrorMessage')}
                        type="text"
                        value={formCompanyAddress}
                        width="100%"
                        max={50}
                        validationCallback={(valid, text) => { setFormCompanyAddressIsValid(valid); console.log('validation formCompanyAddressIsValid(:', valid, text) }}

                    />

                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.PostalCode')}
                        loading={isLoading}
                        setValue={setFormCompanyPostalCode}
                        placeholder={t('NewAccount.Company.Form.PostalCodePlaceholder')}
                        required={false}
                        showValidationError={formCompanyPostalCode !== null}
                        forceValidations={true}
                        // validationRegExpString= {vatRegExp.Regex}
                        validationRegExpString="^.{0,10}$"
                        validationErrorMessage={t('NewAccount.Company.Form.PostalCodeValidationErrorMessage')}
                        type="text"
                        value={formCompanyPostalCode}
                        width="100%"
                        max={10}
                        validationCallback={(valid, text) => { setFormCompanyPostalCodeIsValid(valid); console.log('validation formCompanyPostalCodeIsValid:', valid, text) }}

                    />

                    <Input
                        active={true}
                        label={t('NewAccount.Company.Form.City')}
                        loading={isLoading}
                        setValue={setFormCompanyCity}
                        placeholder={t('NewAccount.Company.Form.CityPlaceholder')}
                        required={false}
                        showValidationError={formCompanyCity !== null}
                        forceValidations={true}
                        validationRegExpString="^.{0,50}$"
                        validationErrorMessage={t('NewAccount.Company.Form.CityValidationErrorMessage')}
                        type="text"
                        value={formCompanyCity}
                        width="100%"
                        max={50}
                        validationCallback={(valid, text) => { setFormCompanyCityIsValid(valid); console.log('validation formCompanyCityIsValid:', valid, text) }}
                    />

                    <div>
                        <Dropdown
                            active={true}
                            buttonMode={false}
                            defaultValue={null}
                            labelText={t('NewAccount.Company.Form.Country')}
                            loading={isLoading}
                            numberOfVisibleOptions={3}
                            openPanelToLeft={false}
                            options={
                                countriesData?.map((item) => ({
                                    selected: selectedCountry === item,
                                    countryCode: item,
                                    text: t('Country.' + item)
                                }))
                            }
                            placeholder={t('NewAccount.Company.Form.CountryPlaceholder')}
                            searchLabel="Search"
                            searchable={true}
                            selectionCallback={item => setSelectedCountry(item.countryCode)}
                        />
                    </div>
                </HorizontalFlow>


                <PageOverlay isVisible={isLoadingPayment} message='Processing payment link, please wait a moment...' />
            </CSMasterpage>
        </>
    )
}