import React, { useState, useEffect, useCallback } from 'react'
import { Button } from 'tridonic-web-ui';
import services from '../../../../services/generic/services';
import accessTokenProvider from '../../../../services/generic/session'
import storageOverlayType from "../../../../enum/storageOverlayType";

export const hardcoded_starYellow = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1024px-FA_star.svg.png' 
export const hardcoded_star   = 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Empty_Star.svg/1200px-Empty_Star.svg.png' 
export const hardcoded_file = 'https://www.iconpacks.net/icons/1/free-icon-document-901.png'
export const hardcoded_folder = "https://icons-for-free.com/download-icon-folder-131964753094019398_512.png"
export const storageContextActions = [
    {
        id: storageOverlayType.RenameFolder,
        text: 'Rename',
        role: ['admin'],
        isFolder: true 
    },
    {
        id: storageOverlayType.RenameFile,
        text: 'Rename',
        role: ['admin', 'member'],
        isFolder: false 
    },
    // { 
    //     id: 'ACTION_FileUpload',
    //     text: 'Upload file',
    //     role: ['admin', 'member'],
    //     isFolder: true 
    // },
    // {
    //     id: storageOverlayType.CreateFolder,
    //     text: 'New folder',
    //     role: ['admin'],
    //     isFolder: true 
    // },
    {
        id: storageOverlayType.PermissionsFolder,
        text: 'Permissions',
        role: ['admin'],
        isFolder: true 
    },
    {
        id: storageOverlayType.DeleteFolder,
        text: 'Delete',
        role: ['admin', 'member'],
        isFolder: true 
    },
    {
        id: storageOverlayType.DeleteFile,
        text: 'Delete',
        role: ['admin', 'member'],
        isFolder: false 
    }
];
export const storageContextActionsNoAdmin = [
    {
        id: storageOverlayType.RenameFile,
        text: 'Rename',
        role: ['admin', 'member'],
        isFolder: false 
    },
    {
        id: storageOverlayType.DeleteFile,
        text: 'Delete',
        role: ['admin', 'member'],
        isFolder: false 
    }
];


export const companyStorageData = [
    {
        isFolder:true,
        child: <>
        <div onClick={(e,  item) => {getFolder(e);}}><img width="30" style={{ padding: 10, paddingRight:30}} src={hardcoded_folder} /></div><label>Folder name</label></>,
        detailActionActive: true,
        id: 111,
        trailingActionActive: true
    },
    {
        child: <><img width="30" style={{ padding: 10, paddingRight:30}} src={hardcoded_star} onClick={() => {setFavorite(true)}} /><label>NomeFileId111.pdf</label></>,
        detailActionActive: true,
        id: 111,
        trailingActionActive: true
    },
    {
        child: <><img width="30" style={{ padding: 10, paddingRight:30 }} src={hardcoded_starYellow} /><label>NomeFileId222.pdf</label></>,
        detailActionActive: true,
        id: 222,
        trailingActionActive: true
    },
    {
        child: <><img width="30" style={{ padding: 10, paddingRight:30 }} src={hardcoded_starYellow} /></>,
    }
]; 



export function setFavorite(isTrue){
    console.log('set favorite');
   
}

export function getFolder(e, storageItem){
}

export function searchItems(e){
    return;
}

/************************************* */


export  function fetchStorageData({companyId, folderId, onSuccess, onError}) { 
    let results =  services
        .getStorageData({ 
            tokenProvider: accessTokenProvider, 
            companyId: companyId, 
            folderId: folderId })
        .then(results => {
            if (results.success) {
                onSuccess(results.data);
            } else {
                //todo: callbackObError
                if (results.data.isActionError) {
                    addNotification({ text: t(results.data.message), type: 'critical' })
                } else {
                    // setCriticalError(results.data);
                }
            }
            return results.success;
        }).catch((err) => {
            console.error(err);
            //TODO do something here
        }).finally(() => {
            //  setIsLoading(false);
            //  setIsDataLoaded(true);
        });
};
 