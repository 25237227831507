import React, { useRef, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useParams, useLocation, useHistory } from "react-router";
import {Breadcrumb, Checkbox,HorizontalFlow, Input, Dropdown, Button, useNotificationsContext, useThemeContext, InputSearch, ListView, Svg } from 'tridonic-web-ui';
import { useTranslation } from 'react-i18next';
import accessTokenProvider from '../../../../services/generic/session'
import CompanyTabs from '../../../../enum/CompanyTabs';
import services from '../../../../services/generic/services';
import permition from '../../../../services/generic/permition';
import CompanyMasterpage from '../../../../components/CompanyMasterpage';
import StorageTabPageStyle from './StorageTabPageStyle';
import notificationsHelper from '../../../../utils/notificationsHelper';
import { getStorageData, fetchStorageData, getFolder, hardcoded_file, hardcoded_starYellow, hardcoded_star,hardcoded_folder, storageContextActions,storageContextActionsNoAdmin,  companyStorageData} from './StorageTabPageFunctions'

import { useAppContext } from '../../../../AppContext'
import ListFeatures from '../../../../components/ListFeatures';
import BreadcrumbV2 from '../../../../components/TDS/BreadcrumbV2';
import ListViewV2 from '../../../../components/TDS/ListViewV2';

import StorageOverlay from "./StorageOverlay.jsx"; 
import StoragePermissionsOverlay from "./StoragePermissionsOverlay.jsx"; 
import StorageUploadOverlay from "./StorageUploadOverlay.jsx"; 
import StorageDeleteOverlay from "./StorageDeleteOverlay.jsx"; 
import storageOverlayType from "../../../../enum/storageOverlayType";



export default function StorageTabPage({ 

}) {
   
    const location = useLocation();
    const companyNameParam = location.state?.companyName;
    const history = useHistory();
    const { addNotification } = useNotificationsContext();
    const { storagePath, setStoragePath } = useAppContext()
  
    const { t } = useTranslation();
    const {theme} = useThemeContext();
    const { EmptyDiv } = useMemo(() => StorageTabPageStyle(theme), [theme]);
    const [isLoading, setIsLoading] = useState(false);
 
    const [companyName, setCompanyName] = useState('');
    const { companyId } = useParams();

    const [criticalError, setCriticalError] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [companyStoreData, setCompanyStoreData] = useState('');
    const [breadcrumbStorage, setbreadcrumbStorage] = useState([]);

    const [storageOverlayVisible, setStorageOverlayVisible] = useState(false)
    const [storageDeleteOverlaylayVisible, setStorageDeleteOverlayVisible] = useState(false)
    const [storagePermissionsOverlayVisible, setStoragePermissionsOverlayVisible] = useState(false)
    const [storageUploadOverlayVisible, setStorageUploadOverlayVisible] = useState(false)
    
    
    const [contextOverlayData, setContextOverlayData] = useState({})
    //const [currentFolderId, setCurrentFolderId] = useState(null)
    
    const [isAccountHolder, setIsAccountHolder] = useState(false);
    const [rootFolderId, setRootFolderId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasWritePermission, setHasWritePermission] = useState(false);
    const addFolderOption = isAdmin ? "first" : "none"
    const addFileOption  = hasWritePermission ? "first" : "none"
    const addContextMenu = isAdmin ? storageContextActions : storageContextActionsNoAdmin
    // const addFileOption  =  "first" 
    
    useEffect(() => { 

        fetchIsAdminAndAccountHolder(); 

        fetchContent( {id:companyId});

        setBreadcrumbDefault();
        //    setStoragePath('carago!!!!')
    }, []);

    const searchItems = useCallback(
        (value) => {
            if (value && value.length >= 3) {
                setSearchBy(value)
            }
        },
        [],
    )


    async function  fetchIsAdminAndAccountHolder(){
        
        const userResponse = await services.getUser({
            tokenProvider: accessTokenProvider
        })
        if (userResponse.success) {           

             setIsAdmin( await permition.isAdminInCompany(
                {
                    companies: userResponse.data.companies,
                    companyId: companyId
                }))
        } else {
            if (userResponse.data.isActionError) {
                addNotification({
                    text: t(userResponse.data.message),
                    type: 'critical'
                })
            } else {
                //  setErrorTemplate(t(userResponse.data.message))
            }
        }

        
            // get Companydata
            const response = await services.getCompanyByCompanyId({
                tokenProvider: accessTokenProvider,
                companyId: companyId
            })

            if (response.success) {
                const data = response.data;             
                setIsAccountHolder(permition.isCompanyAccountHolder({company: data, userId: userResponse.data.id}));    
                setIsLoading(false)
            } else {
                if (response.data.isActionError) {

                    if (response.data.status === 404) {
                        response.data.message = 'Account.Message.NotFound'
                        setCriticalError(response.data)
                        setIsLoading(false)
                        return
                    }
                    setCriticalError(response.data)
                } else {
                    setCriticalError(response.data)
                }
            }


    }
  
    function validateIsRoot(folderId){
        if(folderId == undefined){
            folderId = rootFolderId
        }

        return folderId;
    }

    function fetchContent({id, folderId}){
        
        fetchStorageData({
            companyId: id,  
            folderId: folderId, 
            onSuccess: (data) => {
                
                if(data.isRootFolder){
                    setRootFolderId(data.folderId);
                }

                setHasWritePermission(data.hasWritePermissions && !data.isRootFolder); //aki
                
                setCompanyStoreData(
                    convertStorageData({companyStorageData:data.items, parentFolderId: folderId}));
            },
            onError: function(){}
            }); 
    }

    function setBreadcrumb({nodeId, title}){
       
        setbreadcrumbStorage( arr => [...arr, 
            {
                id: nodeId,
                title: title
            }
        ]);
    }

    function setBreadcrumbPull(id){

        if(id == undefined || id  == null)
        {
            setBreadcrumbDefault();
        
        } else {
            
            let array = breadcrumbStorage;
            let a = array.splice(
                        array.findIndex(item => item.id === id)+1);
            
            setbreadcrumbStorage(array);
        }
    }

    function setBreadcrumbDefault(){ 
        
        setbreadcrumbStorage([{ title: t('Breadcrumb.StorageRoot') }]);
    }

    function convertStorageData({companyStorageData, parentFolderId}) {
       
        //items in folder
        return companyStorageData?.map(storageItem => 
            ({
                id: storageItem?.id,
                isFolder:storageItem?.isDirectory,
                name: storageItem.name,
                parentFolderId: parentFolderId,
                child:
                    <>
                        {storageItem?.isDirectory ? (
                            <>
                                <div  onClick={(e) => {
                                    setBreadcrumb({nodeId: storageItem?.id, title: storageItem.name });
                                    fetchContent( {id:companyId, folderId: storageItem.id});
                                    }}> 
                                    <img width="30" style={{ padding: 10, paddingRight:30}} src={hardcoded_folder} />
                                </div>
                                <label>{storageItem.name}</label>
                            </>
                        ) : (
                            <>
                                <img width="30" style={{ padding: 10, paddingRight:30 }} src={storageItem?.isFavorite? hardcoded_starYellow : hardcoded_star} />
                                <img width="26" style={{padding: 0}}  src={hardcoded_file} />
                                    <label>{storageItem.name}</label>
                            </>    
                        )}
                    </>, 
                detailActionActive: true,
                trailingActionActive: true
            }));
    }

    function storageContextMenu({actionType, companyId, parentFolderId, nodeId, name}){

        var parentFolderId = validateIsRoot(parentFolderId);

        let data = {
            actionType: actionType,
            companyId: companyId,
            parentFolderId: parentFolderId,
            nodeId: nodeId,
            name: name
        };

        setContextOverlayData(data);

        switch(actionType){
            case storageOverlayType.RenameFile:
            case storageOverlayType.RenameFolder:
            case storageOverlayType.CreateFolder:{

                setStorageOverlayVisible(true);

            }break;
            case storageOverlayType.DeleteFolder:
            case storageOverlayType.DeleteFile:{

                setStorageDeleteOverlayVisible(true);

            }break;
            case storageOverlayType.PermissionsFolder:{

                setStoragePermissionsOverlayVisible(true);

            }break;
            case storageOverlayType.Upload:{

                setStorageUploadOverlayVisible(true);

            }break;
            default: {
                setNotifications(t('Accounty.OnlineStorage.ErrorServicesnotImplemented'))
                setNotificationsType('error')
            }
        }
    }

        

    return (
        <CompanyMasterpage
            breadcrumb={[{
                pathname: '/',
                title: t('Breadcrumb.Company')
            }, {
                pathname: '/accounts/account',
                title: t('Breadcrumb.Storage')
            }]}
            loading={isLoading}
            title={companyName ? companyName : companyNameParam}
            companyId={companyId}
            tabsEnabled={true}
            tabSelectedIndex={CompanyTabs.Storage}
            criticalError={criticalError}
            isAccountHolder={isAccountHolder}
            isAdmin={isAdmin}
            >
            {
                <>
                    <HorizontalFlow
                        useBottomMargin={false}
                        maxItemsPerLine="4"
                        useTopMargin={false}
                        width="100%">

                    </HorizontalFlow>    
                    {/* {storagePath}  */}
                    <BreadcrumbV2 data={breadcrumbStorage} onClickAction={(e) => {
                        
                        setBreadcrumbPull(e.id);
                        
                        fetchContent( {id:companyId, folderId: e.id});
                        
                        }} /> 
                    <ListViewV2
                        addNewItemPosition={addFolderOption}
                        addFileOption={addFileOption}
                        addNewItemCallback={
                            function noRefCheck() { 

                                let currentFolderId = breadcrumbStorage[breadcrumbStorage.length-1].id;
                                currentFolderId = validateIsRoot(currentFolderId);

                                storageContextMenu({ 
                                    actionType: storageOverlayType.CreateFolder,
                                    companyId: companyId,
                                    parentFolderId: currentFolderId}); 
                             }}
                        addNewItemCallbackUpload={
                            function noRefCheck() { 

                                let currentFolderId = breadcrumbStorage[breadcrumbStorage.length-1].id;
                                currentFolderId = validateIsRoot(currentFolderId);

                                storageContextMenu({ 
                                    actionType: storageOverlayType.Upload,
                                    companyId: companyId,
                                    parentFolderId: currentFolderId}); 
                             }}
                        addNewItemText="Add Folder"
                        itemTitleWidthProfile={[
                            '100%',
                            '100%',
                            '50%',
                            '280px',
                            '320px'
                        ]}
                        alternativeBackground={true}
                        listData={companyStoreData} 
                        noResultsMessage="No files"
                        trailingAction={
                            function noRefCheck(item) { 
                                 storageContextMenu({
                                     actionType:item.trailingAction.id, 
                                     companyId: companyId, 
                                     parentFolderId: item.data.parentFolderId,
                                     nodeId: item.id,
                                     name: item.data.name});
                            }} 
                        trailingActionIcon="more"
                        trailingActionSubActions={addContextMenu}
                        /> 


                    {storageOverlayVisible ?
                        <StorageOverlay key={0} overlayData={contextOverlayData}
                        visible={storageOverlayVisible}
                        setCriticalError={setCriticalError}
                        onSuccessCallback={(data) => fetchContent( {id:data.companyId, folderId: data.parentFolderId})}
                        onClose={() => {setStorageOverlayVisible(false); }}
                    ></StorageOverlay> : null
                    }
                    
                    <StorageDeleteOverlay key={1} overlayData={contextOverlayData}
                        visible={storageDeleteOverlaylayVisible}
                        setCriticalError={setCriticalError}
                        onSuccessCallback={(data) => fetchContent( {id:data.companyId, folderId: data.parentFolderId})}
                        onClose={() => {setStorageDeleteOverlayVisible(false); }}
                    ></StorageDeleteOverlay>


                    {storagePermissionsOverlayVisible ?
                        <StoragePermissionsOverlay key={0} overlayData={contextOverlayData}
                        setCriticalError={setCriticalError}
                        onSuccessCallback={(data) => fetchContent( {id:data.companyId, folderId: data.parentFolderId})}
                        onClose={() => {setStoragePermissionsOverlayVisible(false); }}
                    ></StoragePermissionsOverlay> : null
                    }

                   {storageUploadOverlayVisible ?
                        <StorageUploadOverlay key={0} overlayData={contextOverlayData}
                        setCriticalError={setCriticalError}
                        onSuccessCallback={(data) => fetchContent( {id:data.companyId, folderId: data.parentFolderId})}
                        onClose={() => {setStorageUploadOverlayVisible(false); }}
                    ></StorageUploadOverlay> : null
                    }
                </>
            }
        </CompanyMasterpage>
    )
}