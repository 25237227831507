import React from "react";
import "./styles.css";
import { useMemo } from "react";
import { useThemeContext, font_md, Button, useAvailableWidth } from "tridonic-web-ui"


import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import CookieBannerStyle from './CookieBannerStyle';
import { useCallback } from "react";

export default function CookieBanner() {

  const { t } = useTranslation();

  const { theme } = useThemeContext();
  const { Text, Actions, MessageGlobalCookie, Message } = useMemo(() => CookieBannerStyle(theme), [theme]);
  const [accept, setAccepted] = useState(false);
  const [buttonWidth, setbuttonWidth] = useState("");
  
const {
  breakpoint //string, current breakpoint for the viewport, can be: xs, sm, md, lg, xl
} = useAvailableWidth()

  useEffect(() => {

    setAccepted(localStorage.getItem('acceptTerms'))

  }, [])

  function acceptTerms() {
    localStorage.setItem('acceptTerms', true);
    setAccepted(true)
  }

  useEffect(() => {
    let buttonWidth= "250px"
    switch (breakpoint) {
        case 'xs':       
        case 'sm':    
        case 'md':
          buttonWidth = "100%"
          break;
        case 'lg':
          buttonWidth = "250px"
            break;
        case 'xl':
          buttonWidth = "250px"
            break;
        default:
            break;
            
    }
    setbuttonWidth(buttonWidth);
}, [breakpoint])


  return (
    <>
      {accept ? (
        <div></div>
      ) : (
        <MessageGlobalCookie
          role="alertdialog"
          tabIndex="-1"
          id="notice-cookie-block"
        >
          <Message>
            <Text> {t('Common.Cookies.Message')}  <a className="hereLink" href={t('Common.Cookies.HereLink')}>{t('Common.Cookies.Here')}</a>.</Text>
          </Message>
          <Actions>
            <Button active={true}
              text={t('Common.Actions.Accept')}
              onClick={() => acceptTerms()}
              type='primary'
              width={buttonWidth}
            >
            </Button>
          </Actions>
        </MessageGlobalCookie>
      )}

    </>
  );


}

