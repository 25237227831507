 import {
   useThemeContext,
   Svg,
   Tooltip
 } from "tridonic-web-ui";

import { icon_size, ellipsis, font_sm } from "tridonic-web-ui/dist/styles/classes";
import  React, { useMemo, useState } from "react";
import BreadcrumbStyle from './BreadcrumbStyleV2'
// import { Breadcrumb } from "tridonic-web-ui";


export default function Breadcrumb2({data, debug, onClickAction}) {

  const { theme } = useThemeContext()
  const { BreadcrumbContainer, NavItemContainer, NavItem } = useMemo(() => BreadcrumbStyle(theme), [theme])
  const { color_ambient_04 } = theme
//   const { navigate } = useRoutingContext()
  let array = []

  if (!Array.isArray(data) || data.length === 0) {
    array.push({ title: 'Home', url: '/' })
  } else {
    array = data.filter(x => x !== null && x !== undefined)
  }

  function navigateTo(item) {
    onClickAction(item);
  }

  return (
    <BreadcrumbContainer>
      {array.map((item, index) => (
        <NavItemContainer key={`nav-item-${index}`}>
          <NavItem lastItem={index >= array.length - 1}
            onClick={() => { if (index < array.length - 1) { navigateTo(item) } }}
            data-testid={`breadcrumb-${index}`}
          >
            {item.title}
          </NavItem>
          {index < array.length - 1 ? (
             <Svg icon="arrow-r" style={{ }} pathStyle={{
               fill: color_ambient_04,
             }} />
          ) : null}
        </NavItemContainer>

      ))}
    </BreadcrumbContainer>
  )
}
