

const permition = {
    isAdminInCompany: async ( {companies, companyId} ) => {       
        const company = companies?.find(x => x.id===parseInt(companyId))
        return company?.roles?.some(item => "Admin" === item.name)
    },
    isCompanyAccountHolder: ( {company, userId} ) => {   
        return company.accountHolder?.id === userId;
    }
}

export default permition