import React, {useState} from 'react'
import { createRipple } from 'tridonic-web-ui/dist/styles/shared'

import {
    Svg,
    Text,
  } from "tridonic-web-ui";
  

  
import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import ListItemNewStyle from './ListItemNewStyle'


export default function ListItemNew({ text = 'New item', active = true, addNewItemCallback = null, addNewItemCallbackUpload = null }) {

    const [{ AddNewItem, NewItemText }, ] =  useState(ListItemNewStyle())
    const { theme } = useThemeContext()
    const { color_brand_primary_02, color_brand_primary_base, color_ambient_04 } = theme


    function handleClick(event) {
        if (active) {
            createRipple(event)
            if (addNewItemCallback) {
                addNewItemCallback()
            }
            if(addNewItemCallbackUpload) {
                addNewItemCallbackUpload()
            } else {
                Console.error('addNewItemCallback is not defined')
            }
        }
    }

    return (
        <AddNewItem onClick={(event) => { handleClick(event) }} pressedColor={color_brand_primary_02} active={active} >
            <Svg icon="add" pathStyle={{ fill: active ? color_brand_primary_base : color_ambient_04 }}></Svg>
            <NewItemText active={active}>{text}</NewItemText>
        </AddNewItem>);
}