import React, { useState, useRef, useEffect, useMemo } from 'react'

import Text from 'tridonic-web-ui/dist/Texts/Text'

import useOnClickOutside from 'tridonic-web-ui/dist/hooks/useClickOutside'

import SvgIcon from 'tridonic-web-ui/dist/Svg'
import { tryParseMeasurePerBreakpoint } from 'tridonic-web-ui/dist/styles/classes'
import SkeletonAction from 'tridonic-web-ui/dist/Loadings/Skeleton/Action'
//import SkeletonText from 'tridonic-web-ui/dist/Loadings/Skeleton/Text'

import { createRipple } from 'tridonic-web-ui/dist/styles/shared'
import ListItemStyle from './ListItemStyle'
import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import EllipsisTooltip from 'tridonic-web-ui/dist/Texts/EllipsisTooltip'



export default function ListItem({
  id,
  data,
  title,
  titleWidth = '50%',
  titleWidthProfile = null,
  child,
  detailAction,
  detailActionActive = true,
  leadingAction,
  leadingActionActive = true,
  trailingAction,
  leadingActionTitle,
  leadingActionIcon = null,
  leadingActionWidth = null,
  trailingActionIcon,
  trailingActionSubActions = null,
  trailingActionActive = true,
  active = true,
  index,
  loading = false,
  showLoadingWithActions = true,
  dataTestId = 'list-item',
  pressedColor = null,
  pressedColorInitialOpacity = null,
  pressedColorAnimationDuration = null,
  alternativeBackground = false,
  detailRowGap = null,
  detailColumnGap = null,
  overrideWithNeutralColors = false
}) {

  const triggerRef = useRef(null)
  const panelRef = useRef(null)

  const [openSubActions, setOpenSubActions] = useState(false);
  const [triggerOverlayWidth, setTriggerOverlayWidth] = useState(0);
  const [triggerOverlayHeight, setTriggerOverlayHeight] = useState(0);
  const [xsWidth, setXsWidth] = useState(titleWidth);
  const [smWidth, setSmWidth] = useState(titleWidth);
  const [mdWidth, setMdWidth] = useState(titleWidth);
  const [lgWidth, setLgWidth] = useState(titleWidth);
  const [xlWidth, setXlWidth] = useState(titleWidth);


  const { theme } = useThemeContext()

  const {
    color_brand_primary_base,
    color_ambient_05,
    density_space_lg,
    color_ambient_01,
  } = theme

  const {
    SubActionButtonWrapperSpacer, SubActionButtonWrapper, SubAction, SubActions, Loading,
    SubActionsPanel, LoadingBar, Title, LeadingActionIcon, TrailingAction, LeadingAction, ListItemDetail, FlexGrow, ListItemContainer
  } = useMemo(() => ListItemStyle(theme), [theme])

  useEffect(() => {
    // console.log('Width for title, default:',titleWidth, ' profiled:', titleWidthProfile)

    if (titleWidthProfile) {
      const o = tryParseMeasurePerBreakpoint(titleWidthProfile)
      if (o) {
        setXsWidth(o.xs)
        setSmWidth(o.sm)
        setMdWidth(o.md)
        setLgWidth(o.lg)
        setXlWidth(o.xl)
      } else {
        setTitleWidthSameSize(titleWidth)
      }
    } else {
      setTitleWidthSameSize(titleWidth);
    }

  }, [titleWidth, titleWidthProfile])

  useOnClickOutside(triggerRef, panelRef, close, `ListItem ${id} outside hook`, false)

  function setTitleWidthSameSize(w) {
    setXsWidth(w)
    setSmWidth(w)
    setMdWidth(w)
    setLgWidth(w)
    setXlWidth(w)
  }


  function close() {
    setOpenSubActions(false);
  }

  function toggleOpenSubActions() {
    setOpenSubActions(val => !val)
    // console.log('val', triggerRef.current.clientWidth)
    setTriggerOverlayWidth(triggerRef.current.clientWidth)
    setTriggerOverlayHeight(triggerRef.current.clientHeight)
  }

  function handleSubActionClick(action) {
    eventParams.trailingAction = action
    trailingAction(eventParams)
  }

  let eventParams = {
    id,
    title,
    leadingActionTitle,
    active,
    index,
    data: data
  }

  const noTitle = title === undefined || title === null || title === '';


  const pressedColorSafe = pressedColor || color_ambient_05
  const pressedColorInitialOpacitySafe = pressedColorInitialOpacity || 0.1
  const pressedColorAnimationDurationSafe = pressedColorAnimationDuration || 600

  return (
    <ListItemContainer data-testid={`${dataTestId}-container`}>
      {loading ?
        <Loading>
          <LoadingBar mainAction={true}>
            <SkeletonText width="15%" />
            <SkeletonText width="30%" style={{ marginLeft: density_space_lg }} />
          </LoadingBar>
          {showLoadingWithActions ? <LoadingBar> <SkeletonAction /></LoadingBar> : null}
        </Loading> :
        <>
          <ListItemDetail
            detailRowGap={detailRowGap}
            detailColumnGap={detailColumnGap}
            active={active && detailActionActive}
            hasAction={active && detailAction !== undefined}
            onClick={event => {
              if (active && detailActionActive) {
                createRipple(event)
                if (detailAction) {
                  detailAction(eventParams)
                }
              }
            }}
            noTitle={noTitle}
            data-testid={`${dataTestId}-detail-action`}
            pressedColor={pressedColorSafe}
            pressedColorInitialOpacity={pressedColorInitialOpacitySafe}
            pressedColorAnimationDuration={pressedColorAnimationDurationSafe}
            alternativeBackground={alternativeBackground}
          >
            {noTitle ? null :
              <Title active={active && detailActionActive} data-testid={`${dataTestId}-title`}
                widthXS={xsWidth}
                widthSM={smWidth}
                widthMD={mdWidth}
                widthLG={lgWidth}
                widthXL={xlWidth}>
                <EllipsisTooltip>{title}</EllipsisTooltip>
              </Title>
            }
            {child}
          </ListItemDetail>

          {leadingActionTitle ?
            <LeadingAction
              leadingActionWidth={leadingActionWidth}
              onClick={event => {
                if (active && leadingActionActive) {
                  createRipple(event)
                  if (leadingAction) { leadingAction(eventParams) }
                }
              }}
              active={active && leadingActionActive}
              data-testid={`${dataTestId}-leading-action`}
              pressedColor={pressedColorSafe}
              pressedColorInitialOpacity={pressedColorInitialOpacitySafe}
              pressedColorAnimationDuration={pressedColorAnimationDurationSafe}
              alternativeBackground={alternativeBackground}
            >
              <FlexGrow />
              <Text text={leadingActionTitle} color={color_brand_primary_base} active={active && leadingActionActive} />
              <FlexGrow />
            </LeadingAction> : null}

          {leadingActionIcon ?
            <LeadingActionIcon
              onClick={event => {
                if (active && leadingActionActive) {
                  createRipple(event)
                  if (leadingAction) { leadingAction(eventParams) }
                }
              }}
              active={active && leadingActionActive}
              data-testid={`${dataTestId}-leading-action`}
              pressedColor={pressedColorSafe}
              pressedColorInitialOpacity={pressedColorInitialOpacitySafe}
              pressedColorAnimationDuration={pressedColorAnimationDurationSafe}
              alternativeBackground={alternativeBackground}
            >
              <FlexGrow />
              <SvgIcon active={active && leadingActionActive} icon={leadingActionIcon} style={{ height: '100%' }} />
              <FlexGrow />
            </LeadingActionIcon> : null}


          {trailingActionIcon ?
            <TrailingAction
              ref={triggerRef}
              opened={openSubActions}
              onClick={event => {
                if (active && trailingActionActive) {
                  createRipple(event)
                  if (trailingActionSubActions) {
                    toggleOpenSubActions();
                  }
                  else if (trailingAction) {
                    trailingAction(eventParams)
                  }
                }
              }}
              active={active && trailingActionActive}
              data-testid={`${dataTestId}-trailing-action`}
              pressedColor={pressedColorSafe}
              pressedColorInitialOpacity={pressedColorInitialOpacitySafe}
              pressedColorAnimationDuration={pressedColorAnimationDurationSafe}
              alternativeBackground={alternativeBackground}
            >
              <SvgIcon icon={trailingActionIcon} active={active && trailingActionActive} style={{ height: '100%' }} fill={overrideWithNeutralColors ? color_ambient_01 : color_brand_primary_base} />

              {trailingActionSubActions ?
                <SubActionsPanel visible={openSubActions} ref={panelRef}>
                  <SubActionButtonWrapper width={triggerOverlayWidth} height={triggerOverlayHeight} >

                  </SubActionButtonWrapper>
                  <SubActionButtonWrapperSpacer width={triggerOverlayWidth} />
                  <SubActions>
                    {trailingActionSubActions.map((item) => (
                      <SubAction key={item.id} onClick={() => handleSubActionClick(item)}>
                        {item.text}
                      </SubAction>
                    ))}
                  </SubActions>
                </SubActionsPanel> : null}
            </TrailingAction> : null}




        </>
      }
    </ListItemContainer>
  )
}
