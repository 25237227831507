import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    color_critical_strong,
    useNotificationsContext
} from "tridonic-web-ui";

import services from "../../../../services/generic/services";
import DeleteConfirmation from "../../../../components/parts/DeleteConfimation";
import notificationsHelper from "../../../../utils/notificationsHelper";
import storageOverlayType from "../../../../enum/storageOverlayType";




export default function StorageOverlay({ overlayData, visible, setCriticalError, onClose, onSuccessCallback }) {

    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(visible)

    useEffect(() => {
        
        if(visible == false)
        return; 

        initComponent();      

    }, [visible])

    
    function initComponent(){
        setNotifications('')
        setNotificationsType('')
        setOverlayVisible(visible)
    }


    function getQuestion(){
        return  t('Account.OnlineStorage.DeleteModal.Question').replace(
            t('Account.OnlineStorage.DeleteModal.QuestionReplaceTag'),
            overlayData.name);
    }


    async function postServer() {
        
        let response; 

        switch(overlayData.actionType)
        {
            case storageOverlayType.DeleteFolder:{
                response = await services.deleteFolder({
                    tokenProvider: accessTokenProvider,
                    companyId: overlayData.companyId,
                    folderId: overlayData.nodeId
                });
            }break;
            default:{ 
                response = await services.deleteFile({
                    tokenProvider: accessTokenProvider,
                    companyId: overlayData.companyId,
                    parentFolderId: overlayData.parentFolderId,
                    fileId: overlayData.nodeId
                });
            }
        }

        if (response.success) {

            addNotification(notificationsHelper.success({text:t(`Account.OnlineStorage.DeleteModal.Success`)}))
           
            onSuccessCallback(overlayData);

            closeOverlay();

        } else {
            if (response.data.isActionError) {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
            } else {
                setCriticalError(response.data)
            }
        }
    }

    function closeOverlay() {
         setOverlayVisible(false);
         onClose();
    }

    return (
        <>

            <Overlay
                actions={[
                    {
                        action: function () { postServer() },
                        title: t(`Account.OnlineStorage.DeleteModal.ActionButton`),
                        type: 'ghost_semantic',
                        color: { color_critical_strong }
                    },
                    {
                        action: function () { closeOverlay(); },  
                        title: t('Account.OnlineStorage.CommonModal.ActionCancel'),
                        type: 'ghost'
                    }
                ]}
                allowCloseWithActions={false}
                centerContentVertically={true}
                error={false}
                errorTemplate={ t('Account.OnlineStorage.GenericError')}
                height={null}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={ t(`Account.OnlineStorage.DeleteModal.Title`)}
                visible={overlayVisible}
            >

                <DeleteConfirmation
                    question={ getQuestion() }
                    warning={t('Account.OnlineStorage.DeleteModal.Warning')}
                >
                </DeleteConfirmation>

            </Overlay>
        </>
    )
}