import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import { icon_size } from 'tridonic-web-ui/dist/styles/classes'
import styled from '@emotion/styled'

export default function ListViewStyleV2() {

    const { theme } = useThemeContext()
    const { density_space_xs, density_space_md, color_ambient_02 } = theme

    const ListViewContainer = styled.div`
        & > *:not(:last-child) {
            margin-bottom: ${density_space_xs};
        }
    `

    const NoResults = styled.div`
        margin-top: ${density_space_md};
        display: flex;
        justify-content: center;

        svg {
            ${icon_size};
            margin-right: ${density_space_xs};
            float: left;
            path {
                fill: ${color_ambient_02};
            }
        }
    `

    return { ListViewContainer, NoResults }
}
