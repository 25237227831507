import React from 'react'
import styled from '@emotion/styled'
import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import { lg, md, md_or_larger, sm, sm_or_larger, xl, xs } from 'tridonic-web-ui/dist/styles/media-queries'
import { getRippleCss } from 'tridonic-web-ui/dist/styles/shared'
import { ellipsis, getFontMD, getShadowBox, icon_size } from 'tridonic-web-ui/dist/styles/classes'
export default function ListItemStyle(theme) {


    const {  color_ambient_02,
        color_ambient_04,
        color_ambient_07,
        color_ambient_08,
        color_ambient_09,
        color_brand_primary_base,
        density_space_3xs,
        density_space_md,
        density_space_sm,
        density_space_xs,
        shape_radius_xs,
        color_ambient_05,
        density_space_lg,
        shape_radius_sm,
        density_space_xxs} = theme

    const ListItemContainer = styled.div`
    display: flex;
  `

    const FlexGrow = styled.span`
    flex-grow: 1;
  `

    const ListItemDetail = styled.div`
    position: relative;
    overflow: hidden;
  
    display: flex;
    flex-wrap: wrap;
    ${props => props.detailRowGap ? `row-gap: ${props.detailRowGap};` : null}
    ${props => props.detailColumnGap ? `column-gap: ${props.detailColumnGap};` : null}
  
    flex-grow: 1;
    align-items: center;
  
    ${xs} {
      ${props => props.noTitle ? 'padding: 0px;' :
            `
        padding-left: ${density_space_sm};
        padding-right: ${density_space_sm};
        padding-top: ${density_space_xs};
        padding-bottom: ${density_space_xs};
        `}
    }
    ${sm} {
      ${props => props.noTitle ? 'padding: 0px;' :
            `
        padding-left: ${density_space_sm};
        padding-right: ${density_space_sm};
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
        `}
    }
    ${md_or_larger} {
      ${props => props.noTitle ? 'padding: 0px;' :
            `
        padding-left: ${density_space_md};
        padding-right: ${density_space_md};
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
        `}
    }
  
  
    background-color: ${props => props.active ? props.alternativeBackground ? color_ambient_08 : color_ambient_09 : color_ambient_07}; 
    border-radius: ${shape_radius_sm};
    :hover {
      background-color: ${props => !props.active ? color_ambient_07 : color_ambient_07};
    }
  
    ${props => props.active ? 'cursor: pointer' : 'cursor: not-allowed'};
  
    ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)}
  
  `




    const LeadingAction = styled.div`
  
    ${props => props.leadingActionWidth ? `width: ${props.leadingActionWidth};` : null}
    position: relative;
    flex-shrink: 0;
    margin-left: ${density_space_3xs};
  
    display: flex;
    ${xs} {
      display: none;
    }
    flex-direction: column;
    align-items: center;
    justify-content: end;
  
  
    border-radius: ${shape_radius_sm};
  
    padding-left: ${density_space_sm};
    padding-right: ${density_space_sm};
  
    ${xs} {
    
        padding-top: ${density_space_xs};
        padding-bottom: ${density_space_xs};
    }
    ${sm} {
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
    }
    ${md_or_larger} {
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
    }
  
    background-color: ${props => props.active ? props.alternativeBackground ? color_ambient_08 : color_ambient_09 : color_ambient_07};
    ${getFontMD(theme)};
    color: ${props => props.active ? color_brand_primary_base : color_ambient_04};
  
    ${ellipsis};
  
    :hover {
      background-color: ${props => !props.active ? color_ambient_07 : color_ambient_07};
    }
  
    ${props => props.active ? 'cursor: pointer' : 'cursor: not-allowed'};
  
    ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)}
  `

    const TrailingAction = styled.div`
    position: relative;
    flex-shrink: 0;
    
    border-radius: ${shape_radius_sm};
    margin-left: ${density_space_3xs};
    white-space: nowrap;
  
    svg {
      margin-left: ${density_space_sm};
      margin-right: ${density_space_sm};
      ${icon_size};
  
      path {
        fill: ${props => props.active ? color_brand_primary_base : color_ambient_04};
      }
    }
    
    display: flex;
    flex-direction: column;
    align-items:center;
  
    text-align: center;
  
    background-color: ${props => props.active ? props.alternativeBackground ? color_ambient_08 : color_ambient_09 : color_ambient_07};
  
    ${props => props.active ? 'cursor: pointer;' : 'cursor: not-allowed;'}
  
    :hover {
      background-color: ${props => !props.active ? color_ambient_07 : props.opened ? color_ambient_09 : color_ambient_07};
    }
  
    ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)}
  `



    const LeadingActionIcon = styled.div`
    position: relative;
    
    ${xs} {
      margin-left: ${density_space_3xs};
  
      svg {
        margin-left: ${density_space_sm};
        margin-right: ${density_space_sm};
        ${icon_size};
  
        path {
          fill: ${props => props.active ? color_brand_primary_base : color_ambient_04};
        }
      }
    }
    ${sm_or_larger} {
      display: none;
    }
  
    display: flex;
    flex-direction: column;
    align-items:center;
  
    border-radius: ${shape_radius_sm};
  
    
    white-space: nowrap;
    text-align: center;
  
    background-color: ${props => props.active ? props.alternativeBackground ? color_ambient_08 : color_ambient_09 : color_ambient_07};
  
    ${props => props.active ? 'cursor: pointer;' : 'cursor: not-allowed;'}
  
    :hover {
      background-color: ${props => !props.active ? color_ambient_08 : props.opened ? color_ambient_07 : color_ambient_07};
    }
  
    ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)}
  `

    const Title = styled.div`
    display: inline-block;
    flex-shrink: 0;
    ${xs} {
      width: ${props => props.widthXS};
    }
    ${sm} {
      width: ${props => props.widthSM};
    }
    ${md} {
      width: ${props => props.widthMD};
    }
    ${lg} {
      width: ${props => props.widthLG};
    }
    ${xl} {
      width: ${props => props.widthXL};
    }
  
    ${ellipsis};
  
    color: ${props => props.active ? color_brand_primary_base : color_ambient_05};
    ${getFontMD(theme)};
  
    vertical-align: top;
  `

    const Loading = styled.div`
    display: flex;
    vertical-align: top;
    width: 100%;
  `
    const LoadingBar = styled.div`
  display: flex;
  justify-content: flex-start;
  ${props => props.mainAction ? 'flex-grow: 1' : 'margin-left:' + density_space_3xs};
  padding-left: ${density_space_xs};
  padding-right: ${density_space_xs};
  padding-top: ${density_space_xs};
  padding-bottom: ${density_space_xs};
  background-color: ${color_ambient_09};
  `
    const SubActionsPanel = styled.div`
    position: absolute;
    display: ${props => props.visible ? 'flex' : 'none'};
    top: 0px;
    right: 0px;
    z-index: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content:start;
  `

    const SubActions = styled.div`
    ${getShadowBox(theme)};
    background-color: ${color_ambient_09};
    border-radius: ${shape_radius_sm} 0px ${shape_radius_sm} ${shape_radius_sm};
    overflow: hidden;
  `

    const SubAction = styled.div`
    text-align: left;
    ${getFontMD(theme)};
    color: ${color_brand_primary_base};
  
    ${ellipsis};
  
    :hover {
      background-color: ${color_ambient_07};
    }
  
    cursor: pointer;
  
    ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)};
  
    ${xs} {
      padding-left: ${density_space_sm};
      padding-right: ${density_space_sm};
      padding-top: ${density_space_xs};
      padding-bottom: ${density_space_xs};
    }
    ${sm} {
        padding-left: ${density_space_sm};
        padding-right: ${density_space_sm};
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
    }
    ${md_or_larger} {
        padding-left: ${density_space_md};
        padding-right: ${density_space_md};
        padding-top: ${density_space_sm};
        padding-bottom: ${density_space_sm};
  }
  `

    const SubActionButtonWrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  
    border-bottom: solid ${shape_radius_sm} ${color_ambient_09};
    display: inline-block;
    vertical-align: top;
    border-radius: ${shape_radius_sm} ${shape_radius_sm}  0px 0px;
    z-index: 1;
  
  `

    const SubActionButtonWrapperSpacer = styled.div`
    width: ${props => props.width}px;
    height: ${density_space_xxs};
    background-color: ${color_ambient_09};
    display: inline-block;
    vertical-align: top;
    z-index: 1;
  `

    return {
        SubActionButtonWrapperSpacer, SubActionButtonWrapper, SubAction, SubActions, Loading,
        SubActionsPanel, LoadingBar, Title, LeadingActionIcon, TrailingAction, LeadingAction, ListItemDetail, FlexGrow, ListItemContainer
    }

}