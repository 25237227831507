import styled from "@emotion/styled";
import { ellipsis, font_sm } from "tridonic-web-ui/dist/styles/classes";

export default function BreadcrumbStyle(
    { density_space_xs, color_brand_primary_base, color_ambient_04, color_ambient_08  }) {

    const BreadcrumbContainer = styled.div`
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
    `
    const NavItemContainer = styled.div`
        box-sizing: border-box;
        display: flex;
        align-items: center;
    `

    const NavItem = styled.a`
        box-sizing: border-box;
        padding-left: ${density_space_xs};
        padding-right: ${density_space_xs};
        padding-top: calc(${density_space_xs} + 2px);
        padding-bottom: calc(${density_space_xs} + 2px);
        ${font_sm};
        ${ellipsis};

        color: ${props => (props.lastItem ? color_ambient_04 : color_brand_primary_base)};
        max-width: 200px;
        min-width: 20px;

        cursor: ${props => (props.lastItem ? 'cursor' : 'pointer')};

        text-decoration: none;
        :link {
            text-decoration: none;
        }
        :visited {
            text-decoration: none;
        }
        :hover {
            text-decoration: none;
            color: ${props => (props.lastItem ? color_ambient_04 : color_brand_primary_base)};
            background-color: ${props => (props.lastItem ? 'transparent' : color_ambient_08)};
        }
        :active {
            text-decoration: none;
        }
    `

    return { BreadcrumbContainer, NavItemContainer, NavItem }
}
