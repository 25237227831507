import styled from '@emotion/styled'
import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import { getFontLG } from  "tridonic-web-ui/dist/styles/classes";
import { sm_or_larger } from 'tridonic-web-ui/dist/styles/media-queries'

export default function ComponentTitleStyle() {
    const { theme } = useThemeContext()
    const { density_space_xs, color_ambient_02, density_space_sm } = theme
    const ComponentTitleContainer = styled.h2`
        margin-top: ${density_space_xs};
        margin-bottom: ${density_space_xs};
        padding: 0;
        font-weight: 400;
        ${getFontLG(theme)};
        color: ${color_ambient_02};
        display: ${props => (props.visible ? 'block' : 'none')};

        ${sm_or_larger} {
            margin-top: ${density_space_sm};
            margin-bottom: ${density_space_sm};
        }
    `
    return { ComponentTitleContainer }
}