import { AppProvider } from './AppContext'
// import { StateProvider } from './store'
import { MsalProvider } from '@azure/msal-react';
import Pages from './pages/Pages'
import { Suspense } from 'react';
import LoginPage from './pages/generic/LoginPage/LoginPage';
import { useState } from "react";
import Spinner from 'tridonic-web-ui/dist/Loadings/Spinner';
import { NotificationsProvider, ThemeProvider } from 'tridonic-web-ui'
import withAppInsights from './utils/AppInsights';
import CookieBanner from './components/CookieBanner';


function App({ pca }) {
    const [isAuth, setIsAuth] = useState(false);
    function insAuth() {

        return process.env.REACT_APP_ASK_AUTH !== "true" || localStorage.getItem('isAuth') === "true"
    }

    function isAuthenticate() {
        var data = localStorage.getItem('isAuth');
        setIsAuth(data === "true")
    }

    return (
        <ThemeProvider defaultTheme="tridonic-light" >
            <Suspense fallback={<Spinner></Spinner>}>
                <NotificationsProvider>
                    <MsalProvider instance={pca}>
                        <AppProvider>
                            {!insAuth() ?
                                <LoginPage
                                    setIsAuth={function () { isAuthenticate() }}
                                ></LoginPage> :
                                <Pages></Pages>
                            }
                            <CookieBanner>
                                {onAccept => <CookieBanner onAccept={onAccept} />}
                            </CookieBanner>
                        </AppProvider>
                    </MsalProvider>
                </NotificationsProvider>

            </Suspense>

        </ThemeProvider>

    );
}

export default withAppInsights(App);

