import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import {
    ListView,
    Overlay,
    HorizontalFlow,
    Input,
    useThemeContext,
    Button,
    useNotificationsContext
} from "tridonic-web-ui";
import accessTokenProvider from '../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../services/generic/services";
import { editProfile, logoutRedirectUri } from "../config";
import DeleteConfirmation from "./parts/DeleteConfimation";
import UserItem from "./UserItem";
import CancelActionConfimation from "./parts/CancelActionConfimation";
import notificationsHelper from "../utils/notificationsHelper";
import MyPermitionsStyle from "./MyPermitionsStyle";


export default function MyPermitions({ onClose }) {
    const [myProfile, setMyProfile] = useState({ name: 'loading...' });
    const { addNotification } = useNotificationsContext()
    const history = useHistory()
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState("");
    const [notificationType, setNotificationType] = useState("");
    const [userCompany, setUserCompany] = useState("");
    const [errorTemplate, setErrorTemplate] = useState("");
    const { t } = useTranslation();
    const [notificationsResign, setNotificationsResign] = useState('')
    const [notificationsResignType, setNotificationsResignType] = useState('')
    const [deleteAccountAlertVisible, setDeleteAccountAlertVisible] = useState(false);
    const [notificationsDeleteMyUserAccount, setNotificationsDeleteMyUserAccount] = useState('')
    const [notificationsDeleteMyUserAccountType, setNotificationsDeleteMyUserAccountType] = useState('')
    const [overlayInternalVisible, setOverlayInternalVisible] = useState(true)
    const [overlayResignVisible, setOverlayResignVisible] = useState(false)
    const [companyToResign, setCompanyToResign] = useState('')
    const { theme } = useThemeContext();
    const {color_critical_strong } = theme

    const isAuthenticated = useIsAuthenticated();

    const {Actions, DeleteMyUserAccount} = useMemo(() => MyPermitionsStyle(theme), [theme]);
    useEffect(() => {
        setNotification("")
        setNotificationType("")
        if (isAuthenticated) {
            fetchUserData();
        }
    }, [])

    function doLogout() {
        instance.logoutRedirect({
            postLogoutRedirectUri: logoutRedirectUri
        });
    }

    async function fetchUserData() {
        setIsLoading(true)
        const response = await services.getUser({
            tokenProvider: accessTokenProvider
        })
        if (response.success) {
            const userData = response.data;
            setMyProfile(userData);
            setUserCompany(userData.companies.map((item, index) => ({
                active: true,
                child: <UserItem desc='' name={item.roles?.map(item => t('PremiumAccounts.CardView.' + item.name)).join(', ')} ></UserItem>,
                detailActionActive: false,
                id: item.id,
                title: item.name,
                rawData: item,
            })))

        } else {

            if (response.data.isActionError) {
                addNotification({
                    text: t(response.data.message),
                    type: 'critical'
                })
            } else {
                setErrorTemplate(t(response.data.message))
            }
        }
        setIsLoading(false)
    }

    function handleEditProfile() {
        instance.loginRedirect(editProfile);
    }

    async function extractProfileData() {
        setIsLoading(true);
        const response = await services.getUserExport({
            tokenProvider: accessTokenProvider,
            userId: myProfile?.id
        })

        if (response.success) {
  
            await download({ data: response?.data })
            setIsLoading(false)
            setNotificationType('success')
            setNotification(t('MyPermitions.ExtractProfileData.Message.Success'))

        } else {
            if (response.data.status === 404) {
                setNotificationType('critical')
                setNotification(t('MyPermitions.ExtractProfileData.Message.NotFound'))
            }
        }

        setIsLoading(false)
    }

    function deleteMyAccount() {
        setNotificationsDeleteMyUserAccount('')
        setNotificationsDeleteMyUserAccountType('')
        setOverlayInternalVisible(false);
        setDeleteAccountAlertVisible(true);
    }

    async function deleteAccountAction() {

        setIsLoading(true)

        const response = await services.deleteMyUserAccount({
            tokenProvider: accessTokenProvider,
            userId: myProfile?.id
        });

        if (response.success) {
            setNotificationsDeleteMyUserAccount(t('MyPermitions.DeleteMyAccountUser.Message.Success'))
            setNotificationsDeleteMyUserAccountType('success')

            addNotification(notificationsHelper.success({ text: t('MyPermitions.DeleteMyAccountUser.Message.Success') }))
            fetchUserData()
            setOverlayResignVisible(false)
            //Do logout 
            doLogout()

        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 400) {
                    setNotificationsDeleteMyUserAccount(t('MyPermitions.DeleteMyAccountUser.Message.' + response?.data?.message, { name: response.data.detail }))
                    setNotificationsDeleteMyUserAccountType('error')
                } else {
                    setNotificationsDeleteMyUserAccount(t(response?.data?.message))
                    setNotificationsDeleteMyUserAccountType('error')
                }
            } else {
                setErrorTemplate(t(response.data.message))
            }
        }
        setIsLoading(false)
    }


    function closeDeleteMyAccount() {
        setOverlayInternalVisible(true);
        setDeleteAccountAlertVisible(false);
    }

    async function resign({ company }) {
        setCompanyToResign(company)
        setNotificationsResign('')
        setNotificationsResignType('')
        setOverlayInternalVisible(false)
        setOverlayResignVisible(true)
    }

    function cancelResign() {
        setOverlayInternalVisible(true)
        setOverlayResignVisible(false)
    }

    async function resignConfirm({ company }) {
        setIsLoading(true)
        const response = await services.removeUserFromAccount({
            tokenProvider: accessTokenProvider,
            companyId: company.id,
            userId: myProfile?.id
        });

        if (response.success) {
            setNotificationsResign(t('MyPermitions.Companies.Resign.Message.Success', { company: company.title }))
            setNotificationsResignType('success')


            setNotification(t('MyPermitions.Companies.Resign.Message.Success', { company: company.title }))
            setNotificationType('success')
            setOverlayInternalVisible(true)
            fetchUserData()
            setOverlayResignVisible(false)
            
        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 400) {
                    setNotificationsResign(t('MyPermitions.Companies.Resign.Message.' + response?.data?.message, { company: company.title }))
                    setNotificationsResignType('warning')
                } else {
                    setNotificationsResign(t(response?.data?.message))
                    setNotificationsResignType('error')
                }
            } else {
                setErrorTemplate(t(response.data.message))
            }
        }
        setIsLoading(false)
    }

    async function download({ data }) {
        const fileName = "MyProfile";
 
        const json = JSON.stringify(data, undefined, 4);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <Overlay
                active={true}
                allowCloseWithActions={true}
                centerContentVertically={false}
                error={errorTemplate !== ""}
                errorTemplate={errorTemplate}
                height={null}
                loading={false}
                notifications={notification}
                notificationsType={notificationType}
                onClose={() => onClose() }
                title={myProfile?.name}
                visible={overlayInternalVisible}
                widthInColumns={[12, 10, 8, 8, 8]}
            >
                <HorizontalFlow
                    maxItemsPerLine={2}
                    useBottomMargin={false}
                    useTopMargin={false}
                    style={{marginBottom: '32px'}}
                >
                    <Input
                        active={false}
                        label={t('MyPermitions.Email')}
                        loading={isLoading}
                        type="text"
                        value={myProfile?.email}
                        width="100%"
                    />

                    <Actions>
                        <Button active={true}
                            text={t('MyPermitions.EditProfile')}
                            onClick={() => handleEditProfile()}
                            type='secondary'
                            leadingIcon='user'
                        >

                        </Button>
                        <Button active={true}
                            text={t('MyPermitions.ExtractProfileData.Title')}
                            onClick={() => extractProfileData()}
                            type='secondary'
                            leadingIcon='download'
                        >
                        </Button>
                    </Actions>


                </HorizontalFlow>

                <ListView
                    title={t('MyPermitions.Companies.Title')}
                    active={true}
                    addNewItemPosition="none"
                    detailAction={(item) => history.push({ pathname: '/account/' + item?.id })}
                    leadingAction={company => { resign({ company: company }) }}
                    leadingActionTitle={t('MyPermitions.Companies.Resign.Title')}
                    itemDetailActive={false}
                    listData={userCompany}
                    loading={isLoading}
                    showLoadingWithActions={true}
                />
                
                <DeleteMyUserAccount>
                    <Button active={true}
                        text={t('MyPermitions.DeleteMyUserAccount')}
                        onClick={() => deleteMyAccount()}
                        type='ghost_semantic'

                    >
                    </Button>
                </DeleteMyUserAccount>

            </Overlay>

            <Overlay
                actions={[
                    {
                        action: function () { deleteAccountAction() },
                        title: t('Common.Actions.Delete'),
                        type: 'ghost_semantic',
                        color: { color_critical_strong }
                    },
                    {
                        action: function () { closeDeleteMyAccount(); },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                centerContentVertically={true}
                error={false}
                height={null}
                loading={isLoading}
                notifications={notificationsDeleteMyUserAccount}
                notificationsType={notificationsDeleteMyUserAccountType}
                onClose={function () { closeDeleteMyAccount(); }}
                title={t('MyPermitions.DeleteMyAccountUser.Title')}
                visible={deleteAccountAlertVisible}
            >
                <DeleteConfirmation
                    question={t('MyPermitions.DeleteMyAccountUser.Question')}
                    warning={t('MyPermitions.DeleteMyAccountUser.Warning')}
                >
                </DeleteConfirmation>
            </Overlay>

            <CancelActionConfimation
                actions={[
                    {
                        action: function () { resignConfirm({ company: companyToResign }) },
                        title: t('Common.Actions.Resign'),
                        type: 'ghost_semantic'
                    },
                    {
                        action: function () { cancelResign() },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t('MyPermitions.Companies.Resign.Title')}
                notifications={notificationsResign}
                notificationsType={notificationsResignType}
                isVisible={overlayResignVisible}
                question={t('MyPermitions.Companies.Resign.CancelQuestion', { company: companyToResign ? companyToResign.title : '' })}
            >
            </CancelActionConfimation>
        </>
    )
}