
import {
    HorizontalFlow,
    Checkbox,
    useThemeContext,
    Svg,
    Tooltip
} from "tridonic-web-ui";

import styled from "@emotion/styled";
import { font_md } from "tridonic-web-ui/dist/styles/classes";
import { useState } from "react";

export default function CheckboxsView({ title, items = [], isLoading = false, onClick }) {

    const [{ CheckboxContainer, RolesTitle, SVGIcon },] = useState(PageComponents())

    return (
        <>
            <div>
                <RolesTitle key={0}>{title}</RolesTitle>
                <HorizontalFlow
                    useBottomMargin={false}
                    useTopMargin={false}
                    lgItems={3}
                    xlItems={3}
                >
                    {items?.map((item, index) =>
                    (
                        <CheckboxContainer key={index}>
                            <Checkbox key={index} active={item.active}
                                checked={item.checked}
                                text={item.text}
                                onStateChange={(checked) => { item.checked = checked; onClick && typeof onClick === 'function' ? onClick(): null; }} 
                                style={{padding:"0"}}   
                            />
                            <SVGIcon>
                                <Tooltip
                                    align="center"
                                    display="flex"
                                    position="top"
                                    tooltipContent={item.tooltipText}>
                                        <Svg icon="info"></Svg>
                                </Tooltip>
                            </SVGIcon>
                        </CheckboxContainer>
                    )
                    )}

                </HorizontalFlow>
            </div>
        </>
    )
}

function PageComponents() {

    const { theme } = useThemeContext()
    const { density_space_xs, shape_radius_xs, density_space_sm, color_ambient_02, color_ambient_09 } = theme
    const CheckboxContainer = styled.div`
        background-color:${color_ambient_09}; 
        padding: ${density_space_xs};
        display: flex;
        align-items: center;
        border-radius: ${shape_radius_xs};
    `
    const RolesTitle = styled.div`
        ${font_md};
        color: ${color_ambient_02};
        padding-bottom: ${density_space_sm};
        flex: 1;
    `

    const SVGIcon = styled.div`
        display: flex;
        margin: auto 0 auto auto;
    `

    return { CheckboxContainer, RolesTitle, SVGIcon }

}
