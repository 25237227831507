import { POST, DELETE, PUT, PATCH, GET, GETAnonymous, UPLOAD } from "./api";

const services = {
    getUser: async ({ tokenProvider }) => {
        return await GET({ tokenProvider: tokenProvider, url: "/users/me" });
    },
    deleteMyUserAccount: async ({ tokenProvider, userId }) => {
        return await DELETE({ tokenProvider: tokenProvider, url: `/users/${userId}` });
    },
    getUserExport: async ({ tokenProvider, userId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/users/${userId}/export` });
    },
    getCompany: async ({ tokenProvider }) => {
        return await GET({ tokenProvider: tokenProvider, url: "/companies" });
    },
    createCompany: async ({ tokenProvider, data }) => {
        return await POST({ tokenProvider: tokenProvider, url: "/companies", payload: data });
    },
    editCompany: async ({ tokenProvider, companyId, data }) => {
        return await PATCH({ tokenProvider: tokenProvider, url: `/companies/${companyId}`, payload: data });
    },
    deleteCompany: async ({ tokenProvider, companyId }) => {
        return await DELETE({ tokenProvider: tokenProvider, url: `/companies/${companyId}`});
    },
    getCompanyByCompanyId: async ({ tokenProvider, companyId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/companies/${companyId}` });
    },
    updateUserPermition: async ({ tokenProvider, companyId, userId, data }) => {
        return await PUT({ tokenProvider: tokenProvider, url: `/companies/${companyId}/members/${userId}/roles`, payload: data });
    },
    removeUserFromAccount: async ({ tokenProvider, companyId, userId }) => {
        return await DELETE({ tokenProvider: tokenProvider, url: `/companies/${companyId}/members/${userId}` });
    },
    sendInvite: async ({ tokenProvider, companyId, data }) => {
        return await POST({ tokenProvider: tokenProvider, url: `/companies/${companyId}/invites`, payload: data });
    },
    getInvitesByCompany: async ({ tokenProvider, companyId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/companies/${companyId}/invites` });
    },
    cancelInvite: async ({ tokenProvider, companyId, inviteId }) => {
        return await DELETE({ tokenProvider: tokenProvider, url: `/companies/${companyId}/invites/${inviteId}` });
    },
    acceptInvite: async ({ tokenProvider, companyId, inviteId }) => {
        return await PATCH({ tokenProvider: tokenProvider, url: `/companies/${companyId}/invites/${inviteId}` });
    },
    resendInvite: async ({ tokenProvider, companyId, inviteId }) => {
        return await PATCH({ tokenProvider: tokenProvider, url: `/companies/${companyId}/invites/${inviteId}/resend` });
    },
    getPlans: async ({ tokenProvider }) => {
        return await GET({ tokenProvider: tokenProvider, url: "/plan/" });
    },
    getPlanByPlanId: async ({ tokenProvider, planId }) => {
        return await GET({tokenProvider: tokenProvider, url: `/plan/${planId}` });
    },
    getPlansAnonymous: async () => {
        return await GETAnonymous({ url: "/plan/" });
    },
    getPlanByPlanIdAnonymous: async ({ planId }) => {
        return await GETAnonymous({ url: `/plan/${planId}` });
    },
    // Billing
    createSubscription: async ({ tokenProvider, companyId, data }) => {
        return await POST({tokenProvider: tokenProvider, url: `/Billing?companyId=${companyId}`, payload: data });
    },
    updateSubscription: async ({ tokenProvider, companyId }) => {
        return await PUT({tokenProvider: tokenProvider, url: `/Billing?companyId=${companyId}` });
    },
    getPaylink: async ({ tokenProvider, planId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/Billing/paylink?planId=${planId}` });
    },
    getSubscriptionManagementLinks: async ({ tokenProvider, companyId }) => {
        return await GET({tokenProvider: tokenProvider, url: `/Billing/manage?companyId=${companyId}` });
    },

   // Billing
    getCountries: async ({ tokenProvider }) => {
        return await GET({tokenProvider: tokenProvider, url: `/common/countries` });
    },
    getCompanyByCompanyId: async ({ tokenProvider, companyId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/companies/${companyId}` });
    },

    getPlanFeatureByUser: async ({ tokenProvider, planId }) => {
        return await GET({ tokenProvider: tokenProvider, url: `/Subscription/CompanyFeaturesValue/${planId}` });
    },
    
    getPlanFeatureByUser1:  ({ tokenProvider , planId }) => {

        return {
            name: "Free", 
            productName: "Companion Suite",
            features: [
                {

            
                    featureName:"FileSizeKbMax",
                    value: 100,
                    used:  50
                },
                {
                    featureName:"FileCountMax",
                    value: 300,
                    used:  50
                },
                {
                    featureName:"Connect",
                    value:100,
                    used:  50
                },
                {
                    featureName:"LightBranding",
                    value: 700,
                    used:  50
                },
                {
                    featureName:"Monitor",
                    value: 500,
                    used:  50
                }
            ]
           
    }},
    //Storage
    getStorageData: async ({ tokenProvider, companyId, folderId, parameters}) => {
        if(folderId == null){
            return await GET({ tokenProvider: tokenProvider, url: `/storages/${companyId}` });
        }
        
        return await GET({ tokenProvider: tokenProvider, url: `/storages/${companyId}/${folderId}` });
    },
    
    getPermissionsData: async ({ tokenProvider, companyId, folderId}) => {
        return await GET({ tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${folderId}/permissions` });
    },
    updatePermissionsData: async ({ tokenProvider, companyId, folderId, data}) => {
        return await PUT({ tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${folderId}/permissions`, payload: data });
    },
    createFolder: async ({ tokenProvider, companyId, parentFolderId, data }) => {
        return await POST({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${parentFolderId}`, payload: data });
    },
    renameFile: async ({ tokenProvider, companyId, parentFolderId, data }) => {
        return await PATCH({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${parentFolderId}/files`, payload: data });
    },
    renameFolder: async ({ tokenProvider, companyId, folderId, data }) => {
        return await PATCH({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${folderId}`, payload: data });
    },
    deleteFolder: async ({ tokenProvider, companyId, folderId }) => {
        return await DELETE({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${folderId}` });
    },
    deleteFile: async ({ tokenProvider, companyId, parentFolderId, fileId }) => {
        return await DELETE({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${parentFolderId}/files/${fileId}` });
    },
    uploadFile: async ({ tokenProvider, companyId, parentFolderId, data }) => {
        return await UPLOAD({tokenProvider: tokenProvider, url: `/storages/${companyId}/folders/${parentFolderId}/upload`, payload: data });
    },
    uploadToRootFile: async ({ tokenProvider, companyId, data }) => {
        return await UPLOAD({tokenProvider: tokenProvider, url: `/storages/${companyId}/upload`, payload: data });
    }
}

export default services;