import React, { } from 'react'

import {
  Svg,
  Console,
  Text,
} from "tridonic-web-ui";


 import ListItem from './same/ListItem'
// import Svg from '../../Svg'
// import Text from '../../Texts/Text'
 import ComponentTitle from './same/ComponentTitle'
 import ListItemNew from './same/ListItemNew'


import ListViewStyle from './ListViewStyleV2'
// import Console from '../../Console'

/**
 * Callback for adding two numbers.
 *
 * @callback addNewItemCallback
 */

/**
 * List view component, see {@link https://www.sketch.com/s/2ed43717-c479-4687-a4ef-a464fe1736a0/a/25EJ5ke}
 * @param {object} props - All params of ListView.
 * @param {string} props.title - Component title, optional by default will not be presented.
 * @param {string} props.itemTitleWidth - option width of title.
 * @param {boolean} props.active - All component is active, by default is true.
 * @param {Array} props.listData - Array of items to be displayed inside each "card" container.
 * @param {string} props.leadingAction
 * @param {string} props.leadingActionTitle
 * @param {string} props.leadingActionIcon = null
 * @param {string} props.leadingActionWidth = null
 * @param {string} props.detailAction
 * @param {string} props.trailingAction
 * @param {string} props.trailingActionIcon
 * @param {string} props.trailingActionSubActions = null
 * @param {string} props.loading
 * @param {boolean} props.showLoadingWithActions - When loading show skeleton "action" button
 * @param {string} props.noResultsMessage
 * @param {string} props.addNewItemText = 'Add new item'
 * @param {string} props.addNewItemTextUpload = 'Upload File'
 * @param {string} props.addNewItemPosition = 'first'
 * @param {addNewItemCallback} props.addNewItemCallback = null
 * @param {string} props.dataTestId = 'list-view'
 */
export default function ListViewV2({
  title,
  itemTitleWidth = '50%',
  itemTitleWidthProfile = null,
  active = true,
  listData = [],
  leadingAction,
  leadingActionTitle,
  leadingActionIcon = null,
  leadingActionWidth = null,
  detailAction,
  trailingAction,
  trailingActionIcon,
  trailingActionSubActions = null,
  loading,
  showLoadingWithActions = true,
  noResultsMessage,
  addNewItemText = 'Add new item',
  addNewItemTextUpload = 'Upload File',
  addNewItemPosition = 'first',
  addFileOption = 'none',
  addNewItemCallback = null,
  addNewItemCallbackUpload = null,
  alternativeBackground = false,
  detailRowGap = null,
  detailColumnGap = null,
  dataTestId = 'list-view',
  overrideWithNeutralColors = false
}) {

  // Console.log('rerender listview') //TODO clear this
  // const [, forceUpdate] = useReducer(x => x + 1, 0);
  //const { theme } = useThemeContext()
  const { ListViewContainer, NoResults } = ListViewStyle()

  //const [{ ListViewContainer, NoResults }, ] = useState(ListViewStyle(theme))

  // useEffect(() => {
  //   Console.log('theme has changed: ', theme)
  //   //forceUpdate()
  //   //setStyledComponents(ListViewStyle())
  // }, [theme])

  function handleAddNewItem() {
    if (addNewItemCallback) {
      addNewItemCallback()
    }
  }

  
  function handleAddNewItemUpload() {
    if (addNewItemCallbackUpload) {
      addNewItemCallbackUpload()
    }
  }

  function handleContextMenu(item, contextMenu){
    return contextMenu.filter(obj => obj.isFolder == item.isFolder); //TODO: change for type
  }

  if (loading) {
    const loadingCards = [{}, {}, {}]
    return (<ListViewContainer>
      {title ? <ComponentTitle title={title} dataTestId={`${dataTestId}-content-title`} /> : null}

      {loadingCards.map((item, index) => (
        <ListItem
          loading={true}
          key={index}
          active={true}
          showLoadingWithActions={showLoadingWithActions}
          dataTestId={`${dataTestId}-item`}
        />
      ))}
    </ListViewContainer>)
  } else {
    return (
      <ListViewContainer>
        {title ? <ComponentTitle title={title} dataTestId={`${dataTestId}-content-title`} /> : null}

        {addNewItemPosition === 'first' || addNewItemPosition === '' ?
          <>
            <ListItemNew addNewItemCallback={() => handleAddNewItem()} active={active} text={addNewItemText}></ListItemNew> 
          </>
          : null}

        {addFileOption === 'first' ?
          <>
            <ListItemNew addNewItemCallbackUpload={() => handleAddNewItemUpload()} active={active} text={addNewItemTextUpload}></ListItemNew> 
          </>
          : null}


        {listData ? listData.map((item, index) => (
          <ListItem
            key={index}
            data={item}
            id={item.id}
            title={item.title}
            titleWidth={itemTitleWidth}
            titleWidthProfile={itemTitleWidthProfile}
            child={item.child}
            detailAction={detailAction}
            detailActionActive={item.detailActionActive || item.detailActionActive === undefined || item.detailActionActive === null}
            leadingAction={leadingAction}
            leadingActionTitle={item.leadingActionTitle ?? leadingActionTitle}
            leadingActionIcon={leadingActionIcon}
            leadingActionWidth={leadingActionWidth}
            leadingActionActive={item.leadingActionActive}
            trailingAction={trailingAction}
            trailingActionIcon={trailingActionIcon}
            trailingActionSubActions={ handleContextMenu(item, trailingActionSubActions)}
            trailingActionActive={item.trailingActionActive}
            active={active && (item.active || item.active === undefined || item.active === null)}
            index={index}
            loading={false}
            alternativeBackground={alternativeBackground}
            dataTestId={item.title ? `list-item-${item.title.toLowerCase()}` : undefined}
            detailRowGap={detailRowGap}
            detailColumnGap={detailColumnGap}
            overrideWithNeutralColors={overrideWithNeutralColors}
          />
        )) : null}

        {addNewItemPosition === 'last' ?
          <ListItemNew addNewItemCallback={() => handleAddNewItem()} text={addNewItemText} active={active}></ListItemNew> : null}

        {!listData || listData.length === 0 ?
          <NoResults>
            <Svg icon="info" />
            <Text
              text={noResultsMessage ? noResultsMessage : 'No items found.'}
              dataTestId={`${dataTestId}-empty-message`}
            />
          </NoResults> : null
        }

      </ListViewContainer>
    )
  }




}
