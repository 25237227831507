import styled from '@emotion/styled';
import { xs, sm, md, lg, xl } from "tridonic-web-ui";


export default function CookieBannerStyle() {

  const MessageGlobalCookie = styled.div`
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2050;
      overflow: hidden;
      box-shadow: 0 0 10px rgb(0 0 0 / 25%);
      background: white;
      padding: 10px;
      text-align: justify;
      color:#262629;
      opacity: 1;
      
    `

  const Actions = styled.div`
  padding-left: 3px;

        ${xl} {
          float: right; width: 18%;
          text-align: center;
         
          }

        ${lg} {
          float: right; width: 18%;
          text-align: center;
          }

          ${md} {
      
            float: right; width:30%;
            text-align: center;
            }
    
      
        ${sm} {
          padding-top: 10px;
          float: left; width: 100%;
          text-align: center;
        }
        

        ${xs} {
          padding-top: 10px;
          float: left; width: 100%;
          text-align: center;
        
        }

    `

  const Text = styled.span`
`

  const Message = styled.span`

    ${xl} {
      float: left; width:80%
      }
    ${lg} {
      float: left; width:80%
      }

    ${md} {
      float: left; width:68%
    }

    ${sm} {
      width: 90%;
      padding-right: 5px;
    }


    ${xs} {
      width: 90%;
      padding-right: 5px;
    }


`
  return { Text, Actions, MessageGlobalCookie, Message }

}
