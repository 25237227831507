import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    HorizontalFlow,
    Input,
    useNotificationsContext
} from "tridonic-web-ui";
import services from "../../../../services/generic/services";
import CancelActionConfimation from "../../../../components/parts/CancelActionConfimation";
import CheckboxsView from "../../../../components/CheckboxsView";
import notificationsHelper from "../../../../utils/notificationsHelper";



export default function EditUserPermitionOverlay({ companyData, isCurrentUserAccountHolder = false, isCurrentUserAdmin = false, userToEdit = {}, visible, setCriticalError, onClose, loadDataCallback }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [notificationsRemove, setNotificationsRemove] = useState('')
    const [notificationsRemoveType, setNotificationsRemoveType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(false)
    const [roles, setRoles] = useState([])
    const [overlayCancelVisible, setOverlayCancelVisible] = useState(false)
    const [overlayRemoveVisible, setOverlayRemoveVisible] = useState(false)
    const [userToEditIsAccountHolder, setUserToEditIsAccountHolder] = useState(false)
    // Form has changes
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        //reset notification state
        setNotifications('');
        setNotificationsType('');
        // if the user is the account holder, he can't edit the admin role
        let isUserToEditAccountHolder = userToEdit?.id === companyData.accountHolder?.id
        setUserToEditIsAccountHolder(isUserToEditAccountHolder);
        setRoles(companyData?.roles?.map((item) => (
            {
                checked: item?.default || userToEdit.roles?.find(role => role.id === item.id),
                text: item.companyGroupKind !== "Other" ? t('PremiumAccounts.CardView.' + item.companyGroupKind) : item.group.name,
                tooltipText: t('PremiumAccounts.CardView.Description.' + item.companyGroupKind),
                roleId: item?.id,
                active: !isCurrentUserAdmin ? false : (item.companyGroupKind === "Admin" ? !isUserToEditAccountHolder : !item?.default)
            }
        )))
        setIsDirty(false);
        setOverlayVisible(visible)
        setOverlayCancelVisible(false)
        setOverlayRemoveVisible(false)
    }, [companyData, userToEdit, visible, t ])

    async function editUserPermition() {
        const groups = [];
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].checked) {
                groups.push(roles[i].roleId);
            }
        }
        setIsLoading(true)
        const response = await services.updateUserPermition({
            tokenProvider: accessTokenProvider,
            companyId: companyData.companyId,
            userId: userToEdit?.id,
            data: groups
        });

        if (response.success) {
            setNotifications(t('Account.Users.EditUser.Message.Success'))
            setNotificationsType('success')
            addNotification(notificationsHelper.success({text:t('Account.Users.EditUser.Message.Success')}))
            loadDataCallback()
            onClose()

        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 409 || response?.data?.status === 404) {
                    setNotifications(t('Account.Users.EditUser.Message.' + response?.data?.message))
                    setNotificationsType('error')
                } else if (response?.data?.status === 400) {
                    setNotifications(t('Account.Users.EditUser.Message.' + response?.data?.message, { name: userToEdit?.name }))
                    setNotificationsType('error')
                } else {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    }

    async function removeUserFromAccount() {
        setIsLoading(true)
        const response = await services.removeUserFromAccount({
            tokenProvider: accessTokenProvider,
            companyId: companyData.companyId,
            userId: userToEdit.id
        });

        if (response.success) {
            setNotificationsRemove(t('Account.Users.EditUser.Message.Success'))
            setNotificationsRemoveType('success')
            addNotification(notificationsHelper.success({text:t('Account.Users.EditUser.Message.Success')}))
            loadDataCallback()
            onClose()

        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 409 || response?.data?.status === 404) {
                    setNotificationsRemove(t('Account.Users.EditUser.Message.' + response?.data?.message))
                    setNotificationsRemoveType('warning')
                } else if (response?.data?.status === 400) {
                    setNotificationsRemove(t('Account.Users.EditUser.Message.' + response?.data?.message, { name: userToEdit?.name }))
                    setNotificationsRemoveType('error')
                } else {
                    setNotificationsRemove(t(response?.data?.message))
                    setNotificationsRemoveType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    }

    function cancel() {
        setNotifications("")
        setNotificationsType("")
        setOverlayVisible(false);
        setOverlayCancelVisible(isDirty)
        if (!isDirty) {
            onClose()
        }
    }

    function discardCancel() {
        setOverlayVisible(false);
        setOverlayCancelVisible(false)
        onClose()
    }

    function continueWithAction() {
        setOverlayVisible(true);
        setOverlayCancelVisible(false)
    }

    function disassociateUserFromBusinessAccount() {
        setNotificationsRemove("")
        setNotificationsRemoveType("")
        setOverlayVisible(false);
        setOverlayRemoveVisible(true)
    }

    function cancelRemove() {
        setOverlayVisible(true);
        setOverlayRemoveVisible(false)
    }

    return (
        <>
            <Overlay
                actions={[
                    {
                        action: function () { editUserPermition() },
                        title: t('Common.Actions.Save'),
                        type: 'ghost',
                        active: isCurrentUserAdmin
                    },
                    {
                        action: function () { cancel(); },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                active={true}
                destructiveAction={{
                    action: function () { disassociateUserFromBusinessAccount() },
                    title: t('Account.Users.RemoveUser.Title'),
                    active: isCurrentUserAdmin
                }}
                allowCloseWithActions={false}
                centerContentVertically={false}
                error={false}
                errorTemplate="Something went wrong. Please try again later."
                height={null}
                loading={isLoading}
                notifications={notifications}
                notificationsType={notificationsType}
                title={t('Account.Users.EditUser.Title')}
                visible={overlayVisible}
                widthInColumns= {[12,10,8,8,8]}
            >
                <>
                    <HorizontalFlow
                        maxItemsPerLine={2}
                        useBottomMargin={true}
                        useTopMargin={false}
                    >
                        <Input
                            active={false}
                            label={t('Account.Users.EditUser.Form.Name')}
                            loading={isLoading}
                            type="text"
                            value={userToEdit?.name}
                            width="100%"

                        />
                        {userToEdit?.email !== null ?
                            <Input
                                active={false}
                                label={t('Account.Users.EditUser.Form.Email')}
                                loading={isLoading}
                                type="email"
                                value={userToEdit?.email}
                                width="100%"
                            />
                            : <div></div>
                        }

                    </HorizontalFlow>
                    <CheckboxsView 
                        key = {0}
                        title={t('Account.Users.EditUser.RolesSection.Title')}
                        items={roles}
                        onClick={() => setIsDirty(true)}
                    ></CheckboxsView>

                </>
            </Overlay>

            <CancelActionConfimation
                actions={[
                    {
                        action: function () { discardCancel() },
                        title: t('Common.Actions.Discard'),
                        type: 'primary'
                    },
                    {
                        action: function () { continueWithAction() },
                        title: t('Common.Actions.ContinueEditing'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t('Account.Users.RemoveUser.CancelEditTitle')}
                isVisible={overlayCancelVisible}
                question={t('Account.Users.RemoveUser.CancelEditQuestion')}
            >
            </CancelActionConfimation>

            <CancelActionConfimation
                actions={[
                    {
                        action: function () { removeUserFromAccount() },
                        title: t('Common.Actions.Remove'),
                        type: 'ghost_semantic'
                    },
                    {
                        action: function () { cancelRemove() },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t('Account.Users.RemoveUser.RemoveUserAccess')}
                notifications={notificationsRemove}
                notificationsType={notificationsRemoveType}
                isVisible={overlayRemoveVisible}
                question={t('Account.Users.RemoveUser.RemoveUserAccessQuestion', { user: userToEdit?.name ? userToEdit?.name : ''  })}
            >
            </CancelActionConfimation>
        </>
    )
}