import { useThemeContext } from 'tridonic-web-ui/dist/providers/ThemeProvider'
import styled from '@emotion/styled'
import { getRippleCss } from 'tridonic-web-ui/dist/styles/shared' 

export default function ListItemNewStyle() {
    const { theme } = useThemeContext()
    const { color_brand_primary_base,
        density_space_sm,
        density_space_xs,
        shape_radius_sm,
        color_brand_primary_04,
        color_brand_primary_03,
        color_ambient_07,
        color_ambient_04 } = theme

    const AddNewItem = styled.div`
        background-color: ${props => props.active ? color_brand_primary_04 : color_ambient_07};
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: ${shape_radius_sm};
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        padding: ${density_space_sm};
        cursor:${props => props.active ? 'pointer' : 'not-allowed'};

        :hover {
        background-color: ${props => props.active ? color_brand_primary_03 : color_ambient_07};
        }

        ${props => getRippleCss(props.pressedColor, props.pressedColorInitialOpacity, props.pressedColorAnimationDuration)}
    `

    const NewItemText = styled.span`
    margin-left: ${density_space_xs};
    color: ${props => props.active ? color_brand_primary_base : color_ambient_04};
    `

    return { AddNewItem, NewItemText }

}