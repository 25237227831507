
import React from 'react'
import ComponentTitleStyle from './ComponentTitleStyle'

export default function ComponentTitle({ title, dataTestId=null }) {
  const { ComponentTitleContainer } = ComponentTitleStyle()
  return (
    <ComponentTitleContainer
      visible={title !== undefined && title !== null && title !== ''}
      data-testid={dataTestId}
    >
      {title}
    </ComponentTitleContainer>
  )
}
