
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import accessTokenProvider from '../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import CSMasterpage from "../../../components/CSMasterpage";
import notificationsHelper from "../../../utils/notificationsHelper";
import { useNotificationsContext } from "tridonic-web-ui";

export default function AcceptInvitePage() {
    const { t } = useTranslation();
    const history = useHistory()
    const { inviteId, companyId } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotificationsContext()
    const [criticalError, setCriticalError] = useState('');

    useEffect(() => {
        async function fetchAcceptInvite() {
            setIsLoading(true)
            let response = await services.acceptInvite({
                tokenProvider: accessTokenProvider,
                companyId: companyId,
                inviteId: inviteId
            });
            
            if (response.success) {
                addNotification(notificationsHelper.success({text:t('AcceptInvite.Message.Success')}))
                history.push({ pathname: '/' })
            } else {
                if (response?.data?.isActionError) {
                    if (response?.data?.status === 403 || response?.data?.status === 404) {
                        history.push({ pathname: '/' })
                    }
                    setCriticalError(response.data)
                }
            }
            setIsLoading(false)
        }
        fetchAcceptInvite()

    }, [])

    return (
        <>
            <CSMasterpage
                criticalError={criticalError}
                title={t('Breadcrumb.AcceptInvite')}
                loading={isLoading}
                breadcrumb={[
                    {
                        pathname: '/',
                        title: t('Breadcrumb.AppTitle')
                    },
                    {
                        title: t('Breadcrumb.AcceptInvite')
                    }
                ]}
            >
            </CSMasterpage>
        </>
    )
}